import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import Layout from '../components/Layout'
import {
  IProject,
  ProjectsComponent
} from './../components/ProjectsComponent'

export const query = graphql`
  query PLProjectsQuery {
    allContentXlsxPlProjects {
      nodes {
        name
        acronym
        time
        financingInstitution
        programme
        number_
        job
        page
      }
    }
  }
`
const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

interface Nodes {
  nodes: Array<IProject>
}

interface DataSheet {
  allContentXlsxPlProjects: Nodes
}

interface Props {
  data: DataSheet
}

interface Props {
  data: DataSheet
}

const Projekty = ({
  data: {
    allContentXlsxPlProjects: { nodes: projects }
  }
}: Props): ReactElement => {
  const styles = useStyles()

  return (
    <Layout actualTab="Projekty">
      <>
        <p className={styles.title}>Projekty</p>
        <ProjectsComponent projects={projects} />
      </>
    </Layout>
  )
}

export default Projekty
