import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { ReactElement } from 'react'
import { ProjectComponent, IProject } from './ProjectComponent'

const useStyles = makeStyles({
  wrapper: {
    paddingLeft: '20px'
  }
})
interface Props {
  projects: Array<IProject>
  isEn?: boolean
}

export const ProjectsComponent = ({ projects, isEn }: Props): ReactElement => {
  const styles = useStyles()
  return (
    <div className={styles.wrapper}>
      {projects.map(project => (
        <ProjectComponent project={project} isEn={isEn} />
      ))}
    </div>
  )
}
