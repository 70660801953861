import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { ReactElement } from 'react'

const useStyles = makeStyles({
  wrapper: {
    paddingBottom: '20px'
  },
  name: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    paddingBottom: '10px'
  }
})

export interface IProject {
  name: string
  acronym: string
  time: string
  financingInstitution: string
  programme: string
  number_: string
  job: string
  page: string
}

interface Props {
  project: IProject
  isEn?: boolean
}
export const ProjectComponent = ({
  project: {
    name,
    acronym,
    time,
    financingInstitution,
    programme,
    number_,
    job,
    page
  },
  isEn
}: Props): ReactElement => {
  const styles = useStyles()
  const acronymTitle = isEn ? 'Acronym' : 'Akronim'
  const timeTitle = isEn ? 'Time span' : 'Czas trwania'
  const institutionTitle = isEn
    ? 'Financing Institution'
    : 'Instytucja finansująca'
  const programmeTitle = isEn ? 'Programme' : 'Program'
  const numberTItle = isEn ? 'Number' : 'Numer'
  const jobTitle = isEn ? 'Job' : 'Stanowisko'
  const pageTitle = 'URL'

  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>{name}</div>
      <div>{acronym && `${acronymTitle}: ${acronym}`}</div>
      <div>{time && `${timeTitle}: ${time}`}</div>
      <div>
        {financingInstitution && `${institutionTitle}: ${financingInstitution}`}
      </div>
      <div>{programme && `${programmeTitle}: ${programme}`}</div>
      <div>{number_ && `${numberTItle}: ${number_}`}</div>
      <div>{job && `${jobTitle}: ${job}`}</div>
      {page && (
        <div>
          {`${pageTitle}: `}
          <a href={page}>{page}</a>
        </div>
      )}
    </div>
  )
}
